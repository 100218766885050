import React, { Component } from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { transparentize } from "polished";

import storage from "../../../storage";

import Content from "../../layout/Content/Content";
import iconPNG from "./seal-of-cookies.png";

const Disclaimer = Styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 4rem 0;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  z-index: 1000;
`;

const ExtendedContent = Styled(Content)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Icon = Styled.img`
  display: none;
  height: 9rem;
  margin-right: 4rem;
  ${Media.greaterThan("medium")`
    display: block;
  `}
`;

const Text = Styled.div`
  padding-right: 6rem;
  ${Media.greaterThan("medium")`
    padding-right: 9rem;
  `}
`;

const Header = Styled.h4`
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  margin: 0 0 0.5rem 0;
  ${Media.greaterThan("medium")`
    font-size: 2.4rem;
  `}
`;

const Description = Styled.p`
  font-size: 1.3rem;
  margin: 0;
  max-width: 64rem;
  a, a:hover, a:focus, a:active, a:visited {
    color: ${(props) => props.theme.colors.white};
    font-weight: 700;
    text-decoration: underline;
  }
`;

const Close = Styled.div`
  position: absolute;
  top: 50%;
  right: 0rem;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  transition: .3s;
  transform: translateY(-50%);
  &:before {
    ${(props) => props.theme.mixins.icon};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    content: "cross";
  }
  &:hover, &:focus {
    background: ${(props) => transparentize(0.95, props.theme.colors.white)};
  }
  ${Media.greaterThan("medium")`
    right: 1rem;
  `}
`;

class CookieDisclaimer extends Component {
  state = {
    show: false,
  };

  async componentWillMount() {
    const accepted = await storage.getItem("cookie-disclaimer", {
      crossDomain: true,
    });

    this.setState({
      show: !accepted,
    });
  }

  handleAccept = async () => {
    await storage.setItem("cookie-disclaimer", 1, { crossDomain: true });

    this.setState({
      show: false,
    });
  };

  render() {
    if (!this.state.show) {
      return null;
    }

    return (
      <Disclaimer>
        <ExtendedContent>
          <Icon src={iconPNG} alt="Seal approves cookies" />

          <Text>
            <Header>Yet another cookie disclaimer&nbsp;...</Header>

            <Description>
              We use cookies (and other similar technologies) to collect data to
              improve your experience on our site. By using our website, you're
              agreeing to the collection of data as described in our{" "}
              <a href="/privacy#cookies">Cookie Policy</a>.
            </Description>
          </Text>

          <Close onClick={this.handleAccept} />
        </ExtendedContent>
      </Disclaimer>
    );
  }
}

export default CookieDisclaimer;
