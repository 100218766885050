import storage from "../storage";

export const trackQueryParams = async () => {
  try {
    if (window.location.search) {
      const raw = await storage.getItem("ad-tracking-entries", {
        crossDomain: true,
      });
      const entries = raw ? JSON.parse(raw) : {};
      entries[`${window.location}`] = Date.now();
      await storage.setItem("ad-tracking-entries", JSON.stringify(entries), {
        crossDomain: true,
      });
    }
  } catch (error) {
    console.error("Query params for ad tracking error", error);
  }
};

export const trackPromos = async () => {
  try {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const promo = params.get("promo");

    if (promo) {
      await storage.setItem("promo", promo, { crossDomain: true });
    }
  } catch (error) {
    console.error("Promo tracking error", error);
  }
};
