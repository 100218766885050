import * as React from 'react';
import { Component } from 'react';

import { Location } from '@reach/router';
import Media from '@uizard-io/styled-media-query';
import { Link } from 'gatsby-plugin-react-i18next';
import * as throttle from 'lodash/throttle';
import { withTranslation } from 'react-i18next';
import Styled from 'styled-components/macro';
// @ts-ignore

import Button from 'components/buttons/Button';
import News from 'components/layout/Header/News';
import Logo from 'components/logo/index';
import constants from 'constants/index';
import TypeFormButton from 'components/buttons/TypeFormButton';


const HybridLink = ({
  children,
  ...props
}) => {
  if (props.to.includes('http')) {
    // eslint-disable-next-line react/jsx-filename-extension
    return (
      <a href={props.to} {...props}>
        {children}
      </a>
    );
  }

  // @ts-ignore
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link {...props}>{children}</Link>;
};

const TypeFormButtonContainer = Styled(TypeFormButton)`
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    background: ${(props) => (props.type === 'dark'
        ? props.theme.colors.white
        : props.theme.colors.black)};
    border-radius: 1rem;
    transition: height .3s, width .3s;
  }

  ${Media.lessThan('980px')`
    &:before {
      top: 50%;
      width: 0.6rem;
      height: 0;
      left: 0;
    }
    
    &:hover, &:focus, &:focus, &:active {
      &:before {
        height: 2.5rem;
      }
    }

    ${(props) => props.active
      && `
      &:before {
        height: 2.5rem;
      }
    `}
  `}

  ${Media.greaterThan('980px')`
    display: inline-flex;
    align-items: center;
    height: 6rem;
    padding: 0;

    &:before {
      top: auto;
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%, 50%, 0);
      height: 0.6rem;
      width: 0;
    }

    &:hover, &:focus, &:focus, &:active {
      &:before {
        width: 100%;
      }
    }

    ${(props) => props.active
      && `
      &:before {
        width: 100%;
      }
    `}
  `}
`;

const NavLink = Styled(HybridLink)`
  position: relative;
  display: block;
  font-size: 1.6rem;
  padding: 1.5rem 2rem;
  overflow: hidden;
  font-weight: 500;

  &:hover, &:focus, &:focus, &:active {
    color: ${(props) => props.theme.colors.black};
  }

  &:before {
    content: "";
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    background: ${(props) => (props.type === 'dark'
        ? props.theme.colors.white
        : props.theme.colors.black)};
    border-radius: 1rem;
    transition: height .3s, width .3s;
  }

  ${Media.lessThan('980px')`
    &:before {
      top: 50%;
      width: 0.6rem;
      height: 0;
      left: 0;
    }
    
    &:hover, &:focus, &:focus, &:active {
      &:before {
        height: 2.5rem;
      }
    }

    ${(props) => props.active
      && `
      &:before {
        height: 2.5rem;
      }
    `}
  `}

  ${Media.greaterThan('980px')`
    display: inline-flex;
    align-items: center;
    height: 6rem;
    padding: 0;

    &:before {
      top: auto;
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%, 50%, 0);
      height: 0.6rem;
      width: 0;
    }

    &:hover, &:focus, &:focus, &:active {
      &:before {
        width: 100%;
      }
    }

    ${(props) => props.active
      && `
      &:before {
        width: 100%;
      }
    `}
  `}
`;

const Container = Styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => (props.type === 'dark'
      ? props.theme.colors.black
      : props.theme.colors.white)};
  border-bottom: 1px solid ${(props) => (props.type === 'dark' ? '#232323' : '#eee')};
  z-index: 100;
  transition: box-shadow .3s;
  
  ${NavLink} {
    color: ${(props) => (props.type === 'dark'
        ? props.theme.colors.white
        : props.theme.colors.black)};
  }

  ${Media.lessThan('980px')`
    height: 5rem;
    position: sticky;
  `}
`;

const Backdrop = Styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;

  ${(props) => props.isOpen
    && `
    display: block;
  `}

  ${Media.greaterThan('980px')`
    display: none;
  `}
`;

const Content = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;

  ${Media.greaterThan('medium')`
    width: ${(props) => props.theme.global.narrowWidth};
    max-width: calc(100% - 4rem);
  `}

  ${Media.greaterThan('980px')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
  `}
`;

const Inner = Styled.div`
  display: flex;
  align-items: flex-start;
  ${Media.lessThan('980px')`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    z-index: 12;
    background-color: ${(props) => (props.type === 'dark'
        ? props.theme.colors.black
        : props.theme.colors.white)};
  `}
  
  ${Media.lessThan('medium')`
    padding-left: 2rem;
  `}
`;

const ExtendedLogo = Styled(Logo)`
  height: 2rem;
  margin-right: 5rem;
  margin-top: 0;

  ${Media.greaterThan('medium')`
    margin-top: 20px;
  `}
`;

const NavContent = Styled.div`
  ${Media.lessThan('980px')`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: ${(props) => (props.type === 'dark'
        ? props.theme.colors.black
        : props.theme.colors.white)};
    box-shadow: 0 0.4rem 2rem 0 rgba(79, 79, 79, 0.1);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 1.5rem 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
    transition: opacity .0s .3s, transform .3s, visibility .0s .3s, z-index .0s .3s;

    ${(props) => props.isOpen
      && `
      z-index: 10;
      visibility: visible;
      transform: translate3d(0, 0, 0);
      opacity: 1;
      transition: opacity .0s .0s, transform .3s, visibility .0s .0s, z-index .0s .0s;
    `}
  `}
`;

const Links = Styled.div`
  ${Media.greaterThan('980px')`
    position: relative;
    display: flex;
    align-items: flex-start;
    top: auto;
    left: auto;
    width: auto;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
    visibility: visible;
    opacity: 1;
    transform: none;
    margin: 0 -2.5rem;
    z-index: 1;
    transition: none;

    & > * {
      margin: 0 1.2rem;
    }
  `}
`;

const NavButtons = Styled.div`
  padding: 0 2rem;
  align-items: center;
  gap: 2.4rem;

  & > * {
    padding: 0;
    margin-bottom: 1rem;
  }

  ${Media.greaterThan('980px')`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    padding: 0;
    
    & > * {
      margin: 0;
    }
  `}
`;

const NavButtonLink = Styled.a`
  
`;

const NavButton = Styled(Button)`
  display: block;
  width: 100%;
  margin: 1.5rem 0;
  font-size: 1.6rem;
  height: 3.7rem;
  line-height: 3.2rem;
  padding: 0 2.5rem;
  color: ${(props) => (props.type === 'dark' ? 'white' : '#030303')};

  ${Media.greaterThan('980px')`
    margin: 0;
    font-size: 1.6rem;
    height: 3.7rem;
    line-height: 3.2rem;
  `}
`;

const Toggle = Styled.div`
  position: relative;
  height: 100%;
  width: 6rem;

  ${Media.greaterThan('medium')`
    margin-right: -2rem;
  `}

  ${Media.greaterThan('980px')`
    display: none;
  `}

  &:before, &:after {
    ${(props) => props.theme.mixins.icon};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    transition: .3s;
  }

  &:before {
    content: "hamburger";
    opacity: 1;
  }

  &:after {
    content: "cross";
    opacity: 0;
  }

  ${(props) => props.isOpen
    && `
    &:before {
      opacity: 0;
    }
    
    &:after {
      opacity: 1;
    }
  `}
`;

const DropDownContainer = Styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  position: relative;

  ${Media.lessThan('980px')`
    align-items: flex-start;
  `}
`;

const NavLinkMain = Styled(NavLink)`
  ${Media.greaterThan('980px')`
    &:hover + ul {
      display: flex;
      height: auto;
      opacity: 1;
      transition: opacity .2s ease-in;
      -moz-transition: opacity .2s ease-in;
      -webkit-transition: opacity .2s ease-in;
      -o-transition: opacity .2s ease-in;
    }
  `}
`;

const DropDown = Styled.ul`
  background-color: ${(props) => (props.type === 'dark'
      ? props.theme.colors.black
      : props.theme.colors.white)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 2rem;

  ${Media.greaterThan('980px')`
    opacity: 0;
    display: none;
    transition: opacity .2s ease-out;
    -moz-transition: opacity .2s ease-out;
    -webkit-transition: opacity .2s ease-out;
    -o-transition: opacity .2s ease-out;
    height: 0;
    position: absolute;
    top: 60px;
    left: calc(0px - 2rem);
    width: max-content;
    box-shadow: 0 0.4rem 2rem 0  ${(props) => (props.type === 'dark' ? 'rgb(255 255 255 / 25%)' : 'rgb(0 0 0 / 15%)')};
    border-radius: 0.8rem;
    padding: 2rem 2rem 0 2rem;

    &:hover {
      opacity: 1;
      height: auto;
      display: flex;
    }
  `}

  & a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.2rem;

    &:last-child {
      margin-bottom: 0;
    }

    ${Media.lessThan('980px')`
      padding: 0 0 0 2rem;
      margin-bottom: 0.5rem;
    `}
  }
`;

const ContentNav = Styled(Content)`
  max-height: 60px;
  align-items: flex-start;
`;

const NavHighlight = Styled.span`
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: ${(props) => props.theme.colors.gray.darker};
  
  ${Media.lessThan('980px')`
    display: none;
  `}
`;

const Row = Styled.div`
  display: flex;
  flex-direction: row;
`;

const Badge = Styled.span`
  background-color: ${(props) => props.theme.colors.primary};
  font-size: 1.2rem;
  border-radius: 0.8rem;
  padding: 0.2rem 0.8rem;
  margin-left: 0.6rem;
  color: ${(props) => props.theme.colors.black};
`;

class HeaderNavbar extends Component<any, any> {
  state = {
    isTop: true,
    isOpen: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  handleScroll = throttle(() => {
    this.setState({
      isTop: (window.scrollY || document.documentElement.scrollTop) < 150,
    });
  }, 250);

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <Location>
        {({
          location,
        }) => (
          <Container isTop={this.state.isTop} type={this.props.type}>
            {this.props.news === 'hide' ? <React.Fragment /> : <News />}

            <Backdrop isOpen={this.state.isOpen} onClick={this.handleToggle} />

            <ContentNav>
              <Inner isTop={this.state.isTop} type={this.props.type}>
                <Link to="/">
                  <ExtendedLogo
                    variant={
                      this.props.type === 'dark'
                        ? 'primary-dark'
                        : 'primary-light'
                    }
                    size={3}
                  />
                </Link>

                <Toggle
                  isOpen={this.state.isOpen}
                  onClick={this.handleToggle}
                />

                <NavContent isOpen={this.state.isOpen} type={this.props.type}>
                  <Links>
                    <DropDownContainer>
                      <NavLinkMain
                        type={this.props.type}
                        active={
                          location.pathname.indexOf('/product/') !== -1
                          || location.pathname.indexOf('/ui-design/') !== -1
                          || location.pathname.indexOf('/ux-design/') !== -1
                          || location.pathname.indexOf('/wireframing/') !== -1
                          || location.pathname.indexOf('/mockups/') !== -1
                          || location.pathname.indexOf('/prototyping/') !== -1
                            ? 1
                            : 0
                        }
                        to="/product/"
                      >
                        Product
                      </NavLinkMain>

                      <DropDown type={this.props.type}>
                        <NavLink type={this.props.type} to="/ui-design/">
                          UI Design
                          <NavHighlight>
                            UI design made easy
                          </NavHighlight>
                        </NavLink>

                        <NavLink type={this.props.type} to="/ux-design/">
                          UX Design
                          <NavHighlight>
                            Ideate and iterate at speed
                          </NavHighlight>
                        </NavLink>

                        <NavLink type={this.props.type} to="/wireframing/">
                          Wireframing
                          <NavHighlight>
                            UI wireframing streamlined
                          </NavHighlight>
                        </NavLink>

                        <NavLink type={this.props.type} to="/mockups/">
                          Mockups
                          <NavHighlight>Rapidly design UI mockups</NavHighlight>
                        </NavLink>

                        <NavLink type={this.props.type} to="/prototyping/">
                          Prototyping
                          <NavHighlight>Design UI prototypes fast</NavHighlight>
                        </NavLink>
                      </DropDown>
                    </DropDownContainer>

                    <DropDownContainer>
                      <NavLinkMain
                        type={this.props.type}
                        active={
                          location.pathname.indexOf('/ai-design/') !== -1 ? 1 : 0
                        }
                        to="/ai-design/"
                      >
                        AI Design
                      </NavLinkMain>

                      <DropDown type={this.props.type}>
                        <NavLink type={this.props.type} to="/design-assistant/">
                          Design Assistant
                          <NavHighlight>
                            Your very own AI design assistant
                          </NavHighlight>
                        </NavLink>

                        <NavLink
                          type={this.props.type}
                          to="/autodesigner/"
                        >
                          <Row>Uizard Autodesigner <Badge>NEW</Badge></Row>
                          <NavHighlight>Text to mockup UI generation</NavHighlight>
                        </NavLink>

                        <NavLink type={this.props.type} to="/screenshot/">
                          Uizard Screenshot
                          <NavHighlight>
                            Turn screenshots into editable designs
                          </NavHighlight>
                        </NavLink>

                        <NavLink type={this.props.type} to="/wireframe-scanner/">
                          Uizard Wireframe
                          <NavHighlight>
                            Hand-drawn wireframe to mockup
                          </NavHighlight>
                        </NavLink>

                      </DropDown>
                    </DropDownContainer>

                    <DropDownContainer>
                      <NavLinkMain
                        type={this.props.type}
                        active={
                          location.pathname.indexOf('/templates/') !== -1
                            ? 1
                            : 0
                        }
                        to="/templates/"
                      >
                        Templates
                      </NavLinkMain>

                      <DropDown type={this.props.type}>
                        <NavLink
                          type={this.props.type}
                          to="/templates/mobile-app-templates/"
                        >
                          Mobile App Design Templates
                          <NavHighlight>App UI design made easy</NavHighlight>
                        </NavLink>

                        <NavLink
                          type={this.props.type}
                          to="/templates/website-templates/"
                        >
                          Website Design Templates
                          <NavHighlight>Rapid website UI design</NavHighlight>
                        </NavLink>

                        <NavLink
                          type={this.props.type}
                          to="/templates/web-app-templates/"
                        >
                          Web App Design Templates
                          <NavHighlight>
                            Web app UI design simplified
                          </NavHighlight>
                        </NavLink>

                        <NavLink
                          type={this.props.type}
                          to="/templates/tablet-templates/"
                        >
                          Tablet Design Templates
                          <NavHighlight>
                            Design your own tablet app
                          </NavHighlight>
                        </NavLink>

                        <NavLink
                          type={this.props.type}
                          to="/templates/component-templates/"
                        >
                          UI Components Library
                          <NavHighlight>
                            UI components for any project
                          </NavHighlight>
                        </NavLink>
                      </DropDown>
                    </DropDownContainer>

                    <DropDownContainer>
                      <NavLinkMain
                        type={this.props.type}
                        active={
                          location.pathname.indexOf('/solutions/') !== -1
                            ? 1
                            : 0
                        }
                        to="/solutions/"
                      >
                        Solutions
                      </NavLinkMain>

                      <DropDown type={this.props.type}>
                        <NavLink
                          type={this.props.type}
                          to="/solutions/product-managers/"
                        >
                          Product Managers
                          <NavHighlight>
                            Project management made easy
                          </NavHighlight>
                        </NavLink>

                        <NavLink
                          type={this.props.type}
                          to="/solutions/ux-professionals/"
                        >
                          Designers &amp; UX Pros
                          <NavHighlight>
                            Design and iterate ideas in minutes
                          </NavHighlight>
                        </NavLink>

                        <NavLink
                          type={this.props.type}
                          to="/solutions/marketers/"
                        >
                          Marketers
                          <NavHighlight>
                            Deliver for clients at speed
                          </NavHighlight>
                        </NavLink>

                        <NavLink
                          type={this.props.type}
                          to="/solutions/startup-founders/"
                        >
                          Startup Founders
                          <NavHighlight>
                            Bring your startup MVP to life
                          </NavHighlight>
                        </NavLink>

                        <NavLink
                          type={this.props.type}
                          to="/solutions/consultants/"
                        >
                          Consultants &amp; Agencies
                          <NavHighlight>
                            Turn ideas into interactive mockups
                          </NavHighlight>
                        </NavLink>

                        <NavLink
                          type={this.props.type}
                          to="/solutions/developers/"
                        >
                          Developers
                          <NavHighlight>
                            The perfect launchpad for your project
                          </NavHighlight>
                        </NavLink>

                        <NavLink type={this.props.type} to="/enterprise/">
                          Enterprise
                          <NavHighlight>
                            Uizard for large businesses
                          </NavHighlight>
                        </NavLink>
                      </DropDown>
                    </DropDownContainer>

                    <NavLink
                      type={this.props.type}
                      active={
                        location.pathname.indexOf('/blog/') !== -1 ? 1 : 0
                      }
                      to="https://uizard.io/blog/"
                    >
                      {this.props.t('nav.blog')}
                    </NavLink>
                  </Links>

                  <NavButtons>
                    <NavLink
                      type={this.props.type}
                      active={
                        location.pathname.indexOf('/pricing/') !== -1 ? 1 : 0
                      }
                      to="/pricing/"
                    >
                      {this.props.t('nav.pricing')}
                    </NavLink>

                    <TypeFormButtonContainer id="WccWfRLY" type={this.props.type}>
                      Contact sales
                    </TypeFormButtonContainer>

                    <NavLink
                      type={this.props.type}
                      to={`https://${constants.APP_DOMAIN}/auth/login`}
                    >
                       {this.props.t('loginButton')}
                    </NavLink>

                    <NavButtonLink
                      href={`https://${constants.APP_DOMAIN}/auth/sign-up`}
                    >
                      <NavButton variant="primary" solid>
                        {this.props.t('signupButton')}
                      </NavButton>
                    </NavButtonLink>
                  </NavButtons>
                </NavContent>
              </Inner>
            </ContentNav>
          </Container>
        )}
      </Location>
    );
  }
}

export default withTranslation()(HeaderNavbar);
