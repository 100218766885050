import * as React from "react";
import { ThemeProvider } from "styled-components/macro";
import { ApolloProvider } from "react-apollo";
import constants from "../../../constants";
import configureApolloClient from "../../../network";
import theme, { GlobalStyle } from "../../../theme";
import CookieDisclaimer from "../../../components/cards/CookieDisclaimer";
import { trackQueryParams, trackPromos } from "../../../tracking";

const apolloClient = configureApolloClient({ constants });

const App = ({ children, type }) => {
  React.useEffect(() => {
    trackQueryParams();
    trackPromos();
  }, []);
  return (
    <>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyle type={type} />
          {children}
          <CookieDisclaimer />
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
