import fetch from "isomorphic-fetch";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

export default function configureApolloClient({ constants }) {
  const link = new HttpLink({
    uri: constants.GRAPHQL_PATH,
  });

  const cache = new InMemoryCache({
    dataIdFromObject: (o) => o._id,
  });

  const defaultOptions = {
    query: {
      fetchPolicy: "network-only",
    },
  };

  return new ApolloClient({
    link,
    cache,
    defaultOptions,
    fetch,
  });
}
