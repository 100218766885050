import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { transparentize, darken } from "polished";

import * as Loader from "../../loaders/Loader";
import Icon from "../../icons/Icon";

const getBackgroundColor = ({ theme, variant = "primary", solid = false }) => {
  if (variant === "transparent") {
    return "transparent";
  }

  if (variant === "blue") {
    return "#0094FF";
  }

  return transparentize(solid ? 0 : 0.25, theme.colors[variant]);
};

const getBackgroundColorFocus = ({ theme, variant = "primary" }) => {
  if (variant === "transparent") {
    return "transparent";
  }

  if (variant === "blue") {
    return "#0094FF";
  }

  return theme.colors[variant];
};

const getBackgroundColorActive = ({ theme, variant = "primary" }) => {
  if (variant === "transparent") {
    return "transparent";
  }

  if (variant === "blue") {
    return "#0094FF";
  }

  return darken(0.05, theme.colors[variant]);
};

const getBorderColor = ({ theme, variant = "primary" }) => {
  if (variant === "transparent") {
    return "transparent";
  }

  if (variant === "primarylight") {
    return theme.colors.primary;
  }

  if (variant === "blue") {
    return "none";
  }

  return theme.colors[variant];
};

const getBorderColorFocus = getBorderColor;

const getBorderColorActive = getBorderColor;

const getTextColor = ({ theme, variant = "primary" }) => {
  if (variant === "black" || variant === "blue") {
    return theme.colors.white;
  }

  return theme.colors.black;
};

const getBoxShadow = ({ theme, variant = "primary" }) => {
  if (variant === "transparent" || variant === "blue") {
    return null;
  }

  if (variant === "white") {
    return `0 0.4rem 2rem 0 ${transparentize(0.9, theme.colors.black)};`;
  }

  return `0 0.4rem 2rem 0 ${transparentize(0.9, theme.colors[variant])};`;
};

const HideableLoader = Styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: ${(props) => (props.hide ? "hidden" : "visible")};
  opacity: ${(props) => (props.hide ? "0" : "1")};
  font-size: 1.5em;
`;

const HideableCheckmark = Styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: ${(props) => (props.hide ? "hidden" : "visible")};
  opacity: ${(props) => (props.hide ? "0" : "1")};
  font-size: 1.5em;
`;

const HideableText = Styled.span`
  display: block;
  position: relative;
  visibility: ${(props) => (props.hide ? "hidden" : "visible")};
  opacity: ${(props) => (props.hide ? "0" : "1")};
  white-space: nowrap;
`;

const Button = ({
  loading,
  loaderVariant,
  success,
  label,
  children,
  expand,
  ...props
}) => (
  <button type="button" {...props}>
    <HideableLoader hide={!loading} />

    <HideableText hide={loading || success}>{label || children}</HideableText>
  </button>
);

export default Styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: ${(props) => props.theme.fonts.regular};
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.5rem;
  height: 4.4rem;
  background-color: ${getBackgroundColor};
  border: 1px solid transparent;
  border-color: ${getBorderColor};
  color: ${getTextColor};
  box-shadow: ${getBoxShadow};
  border-radius: 0.8rem;
  padding: 0 3rem;
  cursor: pointer;
  transition: .15s;
  text-align: center;
  outline: none;

  ${(props) =>
    props.size === "small" &&
    `
    font-size: 0.7em;
  `}

  ${(props) =>
    props.expand &&
    `
    display: flex;
    width: 100%;
  `}x

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `}

  &:hover, &:focus {
    background-color: ${getBackgroundColorFocus};
    border-color: ${getBorderColorFocus};
  }

  &:active {
    background: ${getBackgroundColorActive};
    border-color: ${getBorderColorActive};
  }

  ${Media.greaterThan("medium")`
    height: 5rem;
    font-size: 2rem;
    line-height: 2rem;
  `}
`;
