import Media from '@uizard-io/styled-media-query';
import Styled from 'styled-components/macro';

const Content = Styled.div`
  width: ${(props) => (props.narrow ? props.theme.global.narrowWidth : props.theme.global.width)};
  max-width: calc(100% - 4rem);
  margin: 0 auto;

  ${Media.greaterThan('small')`
    max-width: calc(100% - 10rem);
  `}
  
  ${Media.greaterThan('medium')`
    max-width: calc(100% - 16rem);
  `}
`;

export default Content;
