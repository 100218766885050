import Styled from "styled-components/macro";

import black from "./black.svg";
import white from "./white.svg";
import primaryLight from "./primary-light.svg";
import primaryDark from "./primary-dark.svg";

const variants = {
  black,
  white,
  "primary-light": primaryLight,
  "primary-dark": primaryDark,
};

const Logo = Styled.img.attrs((props) => ({
  src: variants[props.variant],
  alt: "Uizard",
}))`
  display: block;
  height: ${(props) => props.size || 1}rem;
`;

export default Logo;
