import * as React from "react";
import styled from "styled-components";
import HeaderNav from "./HeaderNav/index";

const HeaderContainer = styled.div``;

const Header = ({ type, news }) => (
  <HeaderContainer>
    <HeaderNav type={type} news={news}/>
  </HeaderContainer>
);

export default Header;
