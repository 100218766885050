import * as React from "react";
import Header from "./Header/Header";
import Footer from "./Footer";
import App from "./App/App";

type LayoutProps = {
  type?: string;
  news?: string;
  children: React.ReactNode;
};

const Layout = ({ children, type, news }: LayoutProps) => (
  <App type={type}>
    <Header type={type} news={news} />
    {children}
    <Footer type={type} />
  </App>
);

export default Layout;

Layout.defaultProps = {
  type: null,
};
