import * as React from "react";
import { PopupButton } from '@typeform/embed-react';
import Styled from 'styled-components/macro';

const TypeFormButton = Styled(PopupButton)`
  font-size: 1.6rem;
  height: 3.7rem;
  line-height: 3.2rem;
  padding: 0;
  margin: 0;
  background: ${(props) => (props.type === 'dark'
        ? props.theme.colors.black
        : props.theme.colors.white)};
  color: ${(props) => (props.type === 'dark'
        ? props.theme.colors.white
        : props.theme.colors.black)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: ${(props) => props.theme.fonts.regular};
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.5rem;
  border: none;
  cursor: pointer;
  text-align: center;
  outline: none;

  &:hover, &:focus, &:focus, &:active {
    color: ${(props) => (props.type === 'dark'
        ? props.theme.colors.white
        : props.theme.colors.black)};
  }
`;

export default TypeFormButton;