import * as React from 'react';

import Styled from 'styled-components/macro';

const NewsContainer = Styled.div`
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.black};
`;

const Celebration = Styled.h5`
    font-weight: 500;
    font-size: 1.6rem;
    margin: 0;
    text-align: center;
`;

const TextContainer = Styled.a`
  color: ${(props) => props.theme.colors.white};
  display: inline-block;
  margin: auto;
`;

const News = () => (
  <NewsContainer>
    {/* <TextContainer href="https://uizard.io/autodesigner/">
      <Celebration>🪄 UPDATE: Autodesigner 1.5 - Generate screens, themes, and images for any project. 🪄</Celebration>
    </TextContainer> */}

    <TextContainer href="/black-friday/">
      <Celebration>🪄 SALE: Black Friday - Get 25% off Uizard Pro with Code BF25 🪄</Celebration>
    </TextContainer>
  </NewsContainer>
);

export default News;
