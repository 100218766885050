import { createGlobalStyle } from "styled-components/macro";
import { normalize } from "polished";

const theme = {
  fonts: {
    regular: "Gelion, Open Sans, Helvetica, Helvetica Neue, Arial",
  },
  global: {
    width: "126rem",
    narrowWidth: "107rem",
  },
  colors: {
    primary: "#ffc600",
    primarylight: "rgba(255,198,0,0.1)",

    danger: "#FF0000",

    white: "#ffffff",
    black: "#030303",

    gray: {
      dark: "#373737",
      pin: "#262626",
      piner: "#232323",
      darker: "#959595",
      mediumer: "#f7f7f7",
      medium: "#cecece",
      light: "#e2e2e2",
      lighter: "#fafafa",
    },

    facebook: "#3b5998",
    twitter: "#00aced",
    linkedin: "#007bb6",
  },
  mixins: {
    icon: `
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'uizard' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      
      /* Enable Ligatures ================ */
      letter-spacing: 0;
      -webkit-font-feature-settings: "liga";
      -moz-font-feature-settings: "liga=1";
      -moz-font-feature-settings: "liga";
      -ms-font-feature-settings: "liga" 1;
      font-feature-settings: "liga";
      -webkit-font-variant-ligatures: discretionary-ligatures;
      font-variant-ligatures: discretionary-ligatures;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `,
  },
};

/* eslint-disable no-unused-expressions */
export const GlobalStyle = createGlobalStyle`
  ${normalize()}

  html {
    font-size: 10px;
    height: 100%;
  }

  body {
    font-family: ${theme.fonts.regular};
    background: ${(props) =>
      props.type === "dark" ? theme.colors.black : theme.colors.white};
    color: ${(props) =>
      props.type === "dark" ? theme.colors.white : theme.colors.black};
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: -0.1px;
    font-variant-ligatures: no-common-ligatures;
  }

  .no-scroll {
    overflow: hidden;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }

  a {
    color: ${(props) =>
      props.type === "dark" ? theme.colors.white : theme.colors.black}; 
    text-decoration: none;
    transition: .3s;
    outline : none;
  }

  a:hover, a:focus, a:active {
    color: ${theme.colors.primary};
  }
`;

export default theme;
