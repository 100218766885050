import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import Styled from 'styled-components/macro';
// import LanguageSelector from '../../form/LanguageSelector/index';

import Content from 'components/layout/Content/Content';
import Logo from 'components/logo/index';
// @ts-ignore

const Container = Styled.footer`
  margin-top: 6rem;
  
  a {
    color: ${(props) => (props.type === 'dark'
        ? props.theme.colors.white
        : props.theme.colors.gray.black)};
  }

  ${Media.greaterThan('medium')`
    margin-top: 10rem;
  `}
`;

const Top = Styled.div`
  ${Media.greaterThan('700px')`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    margin: 0 -2.5rem;

    & > * {
      margin: 0 2.5rem;
    }
  `}
`;

const ExtendedLogo = Styled(Logo)`
  margin: 0 14rem 1.5rem 0;

  ${Media.greaterThan('medium')`
    margin: 0 14rem 0 0;
  `}
`;

const Lists = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto 3rem auto;

  ${Media.greaterThan('700px')`
    justify-content: space-around;
    width: 100%;
    margin-left: auto;
  `}
`;

const List = Styled.div`
  margin: 0 0 3rem 0;
  min-width: 0;
  width: calc(100% / 2 - 2rem);

  ${Media.greaterThan('700px')`
    margin: 0 0 5rem 0;
    width: auto;
  `}
  
  ${Media.greaterThan('large')`
    width: calc(100% / 4);
  `}
`;

const Header = Styled.h5`
  font-size: 1.4rem;
  font-weight: 600;
  color: #7f7f7f;
  letter-spacing: 0px;
  margin: 0 0 2rem 0;
`;

const HeaderSpacing = Styled(Header)`
  margin: 3rem 0 2rem 0;
`;

const ListItems = Styled.ul`
  padding: 0;
  margin: 0;
`;

const ListItem = Styled.li`
  list-style: none;
  margin: 1rem 0;
`;

const LogoWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  width: 15.3rem;
  
  ${Media.greaterThan('large')`
    width: auto;
  `}
`;

const CopyRight = Styled.p`
  margin: 0 0 3rem 0;
  line-height: 1.6rem;
  font-size: 1.3rem;
  
  ${Media.greaterThan('medium')`
    margin: 2rem 0 0 0;
  `}
`;

const Badge = Styled.span`
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 0.8rem;
  padding: 0.2rem 0.8rem;
  font-weight: bold;
  margin-left: 0.6rem;
  color: ${(props) => props.theme.colors.black};
`;

export const HybridLink = ({
  children,
  ...props
}) => {
  if (props.to.includes('http')) {
    // eslint-disable-next-line react/jsx-filename-extension,react/jsx-props-no-spreading
    return (
      <a href={props.to} {...props}>
        {children}
      </a>
    );
  }

  // @ts-ignore
  return <Link {...props}>{children}</Link>;
};

const ExtendedLink = Styled(HybridLink)`
  display: inline-block;
  font-size: 1.3rem;
  letter-spacing: 0px;

  &:hover, &:focus, &:active {
    color: ${(props) => props.theme.colors.gray.dark};
  }
`;

type FooterProps = {
  type: string;
};

const Footer = ({
  type,
}: FooterProps) => {
  const { language, changeLanguage } = useI18next();
  const { t } = useTranslation();
  const Languages = [
    {
      value: 'en',
      label: t('lang.en'),
      disabled: false,
    },
    {
      value: 'es',
      label: t('lang.es'),
      disabled: false,
    },
    {
      value: 'fr',
      label: t('lang.fr'),
      disabled: false,
    },
  ];

  return (
    <Container type={type}>
      <Content narrow>
        <Top>
          <LogoWrapper>
            <Link to="/">
              <ExtendedLogo
                variant={type === 'dark' ? 'white' : 'black'}
                size={3}
              />
            </Link>
            <CopyRight>
              {new Date().getFullYear()} © Uizard Technologies
              <br />
              All rights reserved.
            </CopyRight>

            {/* TEMP: to A/B test localization
          <LanguageSelector
            name="Language picker"
            placeholder={language}
            value={language}
            disabled={false}
            options={Languages}
            onChange={(e) => changeLanguage(e.target.value)} />
          */}
          </LogoWrapper>

          <Lists>
            <List>
              <Header>Uizard</Header>
              <ListItems>
                <ListItem>
                  <ExtendedLink to="/product/">Product</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/ai-design/">AI Design</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/autodesigner/">
                    Autodesigner
                    <Badge>NEW</Badge>
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/screenshot/">Uizard Screenshot</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/wireframe-scanner/">Uizard Wireframe</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/design-assistant/">Design Assistant</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/ui-design/">UI Design</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/ux-design/">UX Design</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/templates/">UI Templates</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/mockups/">Mockups</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/wireframing/">Wireframing</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/prototyping/">Prototyping</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/uizard-vs-design-tools/">
                    Uizard vs Other UI Tools
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/figma-alternative/">
                    Figma Alternative
                  </ExtendedLink>
                </ListItem>
              </ListItems>
            </List>

            {/*
            <List>
              <Header>&nbsp;</Header>
              <ListItems>
                <ListItem><ExtendedLink to="/">React Native</ExtendedLink></ListItem>
                <ListItem><ExtendedLink to="/">Android</ExtendedLink></ListItem>
                <ListItem><ExtendedLink to="/">iOS</ExtendedLink></ListItem>
                <ListItem><ExtendedLink to="/">Sketch</ExtendedLink></ListItem>
              </ListItems>
            </List>
          */}
            <List>
              <Header>Resources</Header>
              <ListItems>
                <ListItem>
                  <ExtendedLink to="https://uizard.io/blog/">Blog</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="https://support.uizard.io/en/">
                    Help Center
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/contact/">Contact</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/about/">About</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/pricing/">Pricing</ExtendedLink>
                </ListItem>
                <ListItem>
                  {/* <ExtendedLink to="/careers/">Careers <Badge>We're hiring!</Badge></ExtendedLink> */}
                  <ExtendedLink to="/careers/">Careers</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/affiliate/">
                    Affiliate<Badge>NEW</Badge>
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/research/">Research</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/press/">Press</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="https://updates.uizard.io">
                    Releases<Badge>What's new?</Badge>
                  </ExtendedLink>
                </ListItem>
              </ListItems>
            </List>
            <List>
              <Header>Solutions</Header>
              <ListItems>
                <ListItem>
                  <ExtendedLink to="/solutions/product-managers/">
                    Product Managers
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/solutions/ux-professionals/">
                    Designers & UX Pros
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/solutions/marketers/">
                    Marketers
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/solutions/startup-founders/">
                    Startup Founders
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/solutions/consultants/">
                    Consultants & Agencies
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/solutions/developers/">
                    Developers
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/enterprise/">Enterprise</ExtendedLink>
                </ListItem>
              </ListItems>
            </List>
            <List>
              <Header>Legal</Header>
              <ListItems>
                <ListItem>
                  <ExtendedLink to="/privacy/">Privacy Policy</ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink to="/terms-of-service/">
                    Terms Of Service
                  </ExtendedLink>
                </ListItem>
              </ListItems>
              <HeaderSpacing>Social Media</HeaderSpacing>
              <ListItems>
                <ListItem>
                  <ExtendedLink
                    to="https://www.facebook.com/uizard.io/"
                    target="_blank"
                    rel="noopener"
                  >
                    Facebook
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink
                    to="https://twitter.com/uizard"
                    target="_blank"
                    rel="noopener"
                  >
                    Twitter
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink
                    to="https://www.linkedin.com/company/uizard/"
                    target="_blank"
                    rel="noopener"
                  >
                    Linkedin
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink
                    to="https://www.instagram.com/uizard"
                    target="_blank"
                    rel="noopener"
                  >
                    Instagram
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink
                    to="https://www.tiktok.com/@uizardio"
                    target="_blank"
                    rel="noopener"
                  >
                    TikTok
                  </ExtendedLink>
                </ListItem>
                <ListItem>
                  <ExtendedLink
                    to="https://www.youtube.com/@uizardio"
                    target="_blank"
                    rel="noopener"
                  >
                    YouTube
                  </ExtendedLink>
                </ListItem>
              </ListItems>
            </List>
            {/*
            <List>
              <Header>&nbsp;</Header>
              <ListItems>
                <ListItem><ExtendedLink to="/">Help Center</ExtendedLink></ListItem>
                <ListItem><ExtendedLink to="/">Community</ExtendedLink></ListItem>
                <ListItem><ExtendedLink to="/">Knowledge Base</ExtendedLink></ListItem>
              </ListItems>
            </List>
          */}
          </Lists>
        </Top>
      </Content>
    </Container>
  );
};

export default Footer;
